import React, {useState, useEffect} from "react";
import Header from "./componant/HeaderNew";
import {useParams} from "react-router-dom";
import DOMPurify from "dompurify";
import {Button} from "@mui/material";
import blogData from "./blogs.json";
import {Helmet} from "react-helmet";

import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import TestimonialSlider from "./componant/Slider";
import ContactUs from "./componant/Contact";
import FAQAccordion from "./componant/FAQAccordion";
import ImageWithModal from "./componant/ImageWithModal ";
import SectionWithVideoBackground from "./componant/Vedio";
import BlogCard from "./componant/BlogCard";
import axios from "axios";
import moment from 'moment'
// import Blogtabs from "./componant/Blogtabs";
function Home() {
  const apiUrl = "https://marketapi.lawblocks.io/"

   const [allblog, setallblog] = useState([])
  
    useEffect(() => {
      getallblog()
    }, []);
    const getallblog = async () => {
      console.log("working")
      var response = await axios.post(apiUrl + 'admin/admin/getblogdetails')
      console.log(response.data.data)
      var arr = []
      for (let i = 0; i < response.data.data.length; i++) {
        
        const element = response.data.data[i];
        if(element.uploadImage != ""){
          arr.push(element)
        }
        console.log(typeof element.uploadImage,"=-=-=-=element")
      }
      setallblog(arr)
    }
    const posts = allblog
  const postss = [
    {
      slug:
        "marketplace-for-lawyers-a-new-era-of-legal-service-delivery-law-blocks-ai",
      title:
        "Marketplace for Lawyers: A New Era of Legal Service Delivery - Law Blocks AI",
      category: "Marketplace for Lawyers",
      date: "July 12, 2024",
      description:
        "Explore how Law Blocks AI is transforming legal service delivery with a new marketplace for lawyers. Discover the benefits of AI-powered legal solutions, streamlined processes, and enhanced client-lawyer interactions.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/Marketplace_slfuz1.jpg",
    },
    {
      slug: "benefits-of-ai-chat-for-legal-professionals-and-clients",
      title: "The Benefits of AI Chat for Legal Professionals and Clients",
      category: "Global Information AI",
      date: "July 12, 2024",
      description:
        "Discover the advantages of AI chat for legal professionals and clients with Law Blocks AI. Learn how AI-powered chat enhances communication, efficiency, and client satisfaction in the legal industry.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ai_chat_sisdav.jpg",
    },
    {
      slug: "how-digital-signature-features-work-on-law-blocks-ai",
      title: "How Digital Signature Features Work on Law Blocks AI",
      category: "Digital Signature",
      date: "July 12, 2024",
      description:
        "Learn how Law Blocks AI enhances digital signature features to provide secure, efficient, and legally binding document signing. Explore the benefits of digital signatures in streamlining legal processes and ensuring document integrity.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ds_thnxrv.jpg",
    },
  ];

  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  // Regular Expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (e) => {
    e.preventDefault(); // Prevent form submission

    // Email validation
    if (!email) {
      setError("Email is required.");
    } else if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
    } else {
      setError(""); // Clear any previous error

      // Perform subscription logic here (e.g., API call)
      console.log("Subscribed with email:", email);
      alert(`Subscribed with email: ${email}`);
    }
  };

  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {slug} = useParams();
  const post = posts.find((p) => p.metaSlug === slug);
  
  if (!post) return <div></div>;

  const createMarkup = (html) => {
    return {__html: DOMPurify.sanitize(html)};
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header blog_body"}>
      <Header />
      <Helmet>
        <title>{post.metaTitle}</title>
        <meta property="og:title" content={post.metaTitle} />
        <meta property="og:description" content={post.metaDescription} />
        <meta property="og:image" content={post.uploadImage} />
        <meta
          property="og:url"
          content={`https://lawblocks.io/blogdetails/${slug}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <main>
        <section
          className="section_defualt bg-gray_section bg-white mt-90 blog_body"
          id="section-4"
        >
          <Container maxWidth="lg" className="pt-4">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className=" mb-3">
                <div className="blg_imag">
                  {post.uploadImage && (
                    <img src={post.uploadImage} alt={post.metaTitle} />
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={8}
                className="d-flex justify-content-center"
              >
                <div className="blg_contant_section">
                  <h1 className="mb-3">{post.metaTitle}</h1>
                  <div className="sectin_cate">
                    <span>{post.blogCategory}</span>
                    <span>
                      <i class="ri-file-edit-line"></i>
                      {post.date}
                    </span>
                    <span>
                      <i class="ri-eye-line"></i>
                      {post.likes}
                    </span>
                  </div>
                  <div
                    dangerouslySetInnerHTML={createMarkup(post.cmsEditor)}
                    className="call_blog"
                  />
                  <a
                    href={post.link}
                    className="onliy_for_link w-100 d-flex justify-content-center"
                  >
                    <Button className="laight_button light_cwnte">
                      Try Now
                    </Button>
                  </a>
                  {/* <div className="quate mb-4">
                    <h6>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Aenean venenatis interdum posuere.{" "}
                    </h6>
                  </div> */}
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} className="">
                <Card
                  sx={{
                    width: "100%",
                    padding: "16px 16px",
                  }}
                  className="card_dark "
                >
                  <CardContent
                    sx={{
                      padding: "0 !important",
                    }}
                  >
                    <div className="link_tabe">
                      <ul>
                        <li>
                          <a
                            href="/blogdetails/ai-legal-document-creation-with-law-blocks-ai"
                            target="_blank"
                          >
                            AI Legal Documents
                          </a>
                        </li>
                        <li>
                          <a
                            href="/blogdetails/ai-legal-document-creation-with-law-blocks-ai"
                            target="_blank"
                          >
                            Smart Legal Contracts
                          </a>
                        </li>
                        <li>
                          <a
                            href="/blogdetails/how-digital-signature-features-work-on-law-blocks-ai"
                            target="_blank"
                          >
                            Digital Signature
                          </a>
                        </li>
                        <li>
                          <a
                            href="/blogdetails/how-secure-are-the-documents-stored-on-law-blocks-ai"
                            target="_blank"
                          >
                            Uploading Blockchain
                          </a>
                        </li>
                        <li>
                          <a
                            href="/blogdetails/benefits-of-ai-chat-for-legal-professionals-and-clients"
                            target="_blank"
                          >
                            Global Information AI
                          </a>
                        </li>
                        <li>
                          <a
                            href="/blogdetails/simplifying-adr-through-arbitration-and-mediation-with-law-blocks-ai"
                            target="_blank"
                          >
                            Mediation
                          </a>
                        </li>
                        <li>
                          <a
                            href="/blogdetails/simplifying-adr-through-arbitration-and-mediation-with-law-blocks-ai"
                            target="_blank"
                          >
                            Arbitration 
                          </a>
                        </li>
                        <li>
                          <a
                            href="/blogdetails/marketplace-for-lawyers-a-new-era-of-legal-service-delivery-law-blocks-ai"
                            target="_blank"
                          >
                            Marketplace for Lawyers
                          </a>
                        </li>
                      </ul>
                    </div>
                  </CardContent>
                </Card>

                {posts.map((post, index) => (
                  <>
                    <a
                      href={`/${post.metaSlug}`}
                      tabIndex="_blank"
                      key={index}
                      className="card_link"
                    >
                      <Card
                        sx={{
                          width: "100%",
                          padding: "16px 16px",
                        }}
                        className="card_dark mt-3 cursoe"
                      >
                        <CardContent
                          sx={{
                            padding: "0 !important",
                          }}
                        >
                          <div className="row bloh_carde">
                            <div className="col-lg-4 d-flex align-items-center">
                              {" "}
                              <img src={post.uploadImage} className="img-fluid" />
                            </div>
                            <div className="col-lg-8 pl-0">
                              <div className="innaer_content">
                                <h4>{post.metaTitle}</h4>
                                <div className="sectin_cate">
                                  <span>{post.blogCategory}</span>

                                  <span>
                                    <i class="ri-eye-line"></i>100
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </a>
                  </>
                ))}
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="section_defualt">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className=" mb-4">
                <div className=" overvide headeing_main align-items-center articelee related ">
                  {/* <span className="lable_badge">Our Blog</span> */}
                  <h2 className="text-captailz text-center">
                    Related articles
                  </h2>
                  <a href="/blog" className="onliy_for_link">
                    <Button className="laight_button light_cwnte">
                      See All Articles
                    </Button>
                  </a>
                </div>
              </Grid>
              {posts.map((post, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className="d-flex justify-content-center"
                  data-aos="fade-up"
                >
                  <BlogCard
                    key={index}
                    title={post.metaTitle}
                    description={post.metaDescription}
                    imagePath={post.uploadImage}
                    category={post.blogCategory}
                    date={moment(post.created_at).format('lll')}
                    slug={post.metaSlug}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>
        <section>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <div className="social_icon">
                  <h4 className="text-center">Follow us on</h4>
                  <ul className="social mt-4">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/lawblocks/"
                      >
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://x.com/lawblockstoken">
                        <i className="bi bi-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://t.me/LawBlocks_updates">
                        <i className="ri-telegram-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://medium.com/@lawblocks">
                        <i className="ri-medium-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.reddit.com/user/LawBlocks/"
                      >
                        <i className="ri-reddit-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://github.com/LawBlocks">
                        <i className="ri-github-fill"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/lawblocks.legal.tech.community/"
                      >
                        <i className="bi bi-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/law_blocks_ai/"
                      >
                        <i className="bi bi-instagram"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="https://api.whatsapp.com/send/?phone=8104453315"
                      >
                        <i className="bi bi-whatsapp"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@lawblocks2982"
                      >
                        <i class="ri-youtube-fill"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt bg-gray_section mt-5 section_marke">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className="">
                <div
                  className=" overvide headeing_main align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  {/* <span className="lable_badge">Our Blog</span> */}
                  <h2 className="text-captailz text-center mb-2">
                    Leading the Way in AI Legal Technology
                  </h2>
                  <p>Get the latest product and management insights.</p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className=" mb-4">
                <div
                  className="input_fore"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                  />

                  <button onClick={handleSubscribe}>Subscribe</button>
                </div>
                <div className="input_fore">
                  {error && <p style={{color: "red"}}>{error}</p>}
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
