import React from "react";
import { Button } from "@mui/material";
import precnet from "../Image/Docs/Law_Blocks_PPT.pdf";
import whitePaper from "../Image/Docs/Law_Blocks_AI_Whitepaper.pdf";
import pitch from "../Image/Docs/Law_Blocks_AI_Pitch_Deck.pdf";
import logo1 from '../Image/logoimage/Black BG Law Blocks AI.jpg'
import logo2 from '../Image/logoimage/LBT 1.jpg'
import logo3 from '../Image/logoimage/LBT 2.jpg'
import logo4 from '../Image/logoimage/Law Blocks AI Transparent.png'
import logo5 from '../Image/logoimage/White BG Law Blocks AI.jpg'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function Footer() {

  const imageFiles = [
    { name: 'image1.jpg', url: logo1 },
    { name: 'image2.jpg', url: logo2 },
    { name: 'image3.jpg', url: logo3 },
    { name: 'image4.jpg', url: logo4 },
    { name: 'image5.jpg', url: logo5 },
  ];

  const downloadZip = async () => {
    const zip = new JSZip();
    const imgFolder = zip.folder('images');

    // Fetch and add images to the zip folder
    await Promise.all(
      imageFiles.map(async (image) => {
        const response = await fetch(image.url);
        const blob = await response.blob();
        imgFolder.file(image.name, blob);
      })
    );

    // Generate the zip file and trigger download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'brand .zip');
    });
  };

  return (
    <>
      <footer>
        <div className="container-fluid pl-5 pr-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="logo_section_f">
                <img
                  src={require("../Image/law-Blocks-logo-dark.png")}
                  className="whiteshow"
                />
                <img
                  src={require("../Image/banner/dark_logo.webp")}
                  className="blackshow"
                />

                <p>
                  Upgrade Your legal practice with Law Blocks AI, <br />
                  Powered by XDC Blockchain Network
                </p>

                <ul className="social mt-4">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/lawblocks/"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://x.com/lawblockstoken">
                      <i className="bi bi-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://t.me/LawBlocks_updates">
                      <i className="ri-telegram-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://medium.com/@lawblocks">
                      <i className="ri-medium-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.reddit.com/user/LawBlocks/"
                    >
                      <i className="ri-reddit-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://github.com/LawBlocks">
                      <i className="ri-github-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/lawblocks.legal.tech.community/"
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/law_blocks_ai/"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                  </li>

                 
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@lawblocks2982"
                    >
                      <i class="ri-youtube-fill"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Company</h4>
                    <ul>
                      <li>
                        <a href="/about-us">About Law Blocks AI</a>
                      </li>
                      <li>
                        <a href="/our-team">About Team</a>
                      </li>

                      <li>
                        <a href={whitePaper} target="_blank">Whitepaper</a>
                      </li>
                      <li>
                        <a href={precnet} target="_blank">
                        Power Point Presentation (PPT){" "}
                        </a>
                      </li>
                      <li>
                        <a href={pitch} target="_blank">
                          Pitch Deck{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Other Links</h4>
                    <ul>
                      <li>
                        <a href="/join-community">Community Support</a>
                      </li>
                      <li>
                        <a href="/blog">Blog</a>
                      </li>

                      <li>
                        <a onClick={downloadZip} style={{ cursor : "pointer"}}>Download Brand Assets</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Tokenomics</h4>
                    <ul>
                      <li>
                        <a href="/lbt-supported-wallets-exchanges">
                          Wallets & Exchanges
                        </a>
                      </li>
                      <li>
                        <a href="/tokenomics">Tokenomics</a>
                      </li>

                      <li>
                        <a href="/partners">Our Partners</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Coming Soon</h4>
                    <ul>
                      <li>
                        <a href="/virtual-courtroom">
                          Virtual Courts in Metaverse
                        </a>
                      </li>
                      <li>
                        <a href="/alternate-dispute-resolution">ADR</a>
                      </li>

                      <li>
                        <a href="/dispute-resolution">Dispute Resolution</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="copywrigt">
                <p>
                  {" "}
                  All rights reserved. Copyright 2024 © LawBlocks, All Right
                  Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Buy Ticket</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="ticket_section">
                <div className="input_form_tivket">
                  <p>
                    Buy<span>Ticket</span>
                  </p>
                  <input type="number" placeholder="" />
                  <div className="amount_bust">~ 0.001 BUSD</div>
                </div>
                <div className="amount_content">
                  <p>
                    Cost(BUSD)<span>0.001 BUSD</span>
                  </p>
                  <div className="youpay">
                    <p>
                      You Pay <span> ~ 0 BUSD</span>
                    </p>
                  </div>
                </div>
                <Button className="header_btn">Connect Wallet</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
